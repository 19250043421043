import { useState, useEffect } from 'react';

export const useContact = (initialValues) => {
	const [ values, setValues ] = useState(initialValues);

	useEffect(() => {
		if(values.captchaToken) {
			fetch(`${process.env.REACT_APP_WP_CONTACT_ENDPOINT}`, {
				method: "POST",
				mode: "cors",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(values)
			})
			.then(res => {
				return res.json()
			})
			.then(success => {
				if(success === false) {
					setValues({
						...values,
						success: false,
						valid_email: false,
						error_message: "There was a problem sending your message. Please try again.",
						response_pending: false,
						captchaToken: null
					})
				} else {
					setValues({
						...values,
						success: true,
						name: "",
						email: "",
						subject: "",
						message: "",
						valid_email: false,
						error_message: null,
						response_pending: false
					})
					setTimeout(() => {
						setValues({
							...values,
							name: "",
							email: "",
							subject: "",
							message: "",
							success: false,
							response_pending: false,
							captchaToken: null
						})
					}, 6000)
				}
			})
		}
	}, [values.captchaToken])

	const onChange = (name, value) => {
		setValues({ ...values, [name]: value });
	}

	const resetToken = () => {
		setValues({
			...values,
			response_pending: false, 
			captchaToken: null
		})
	}

	const validateEmail = () => {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		setValues({
			...values, 
			valid_email: re.test(values.email)
		});
	}

	const verifyCallback = (response) => {
		setValues({
			...values,
			captchaToken: response,
			response_pending: true,
			error_message: null
		})
	}

	return [ values, onChange, validateEmail, verifyCallback, resetToken ];
}

// this is a comment